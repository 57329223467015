<template>
    
</template>

<script>

export default 
{
  methods: 
  {
    SetToken() 
    {
    localStorage.clear();
    this.$store.commit("setToken", this.$route.params.token)
    localStorage.setItem('redirect', this.$route.params.redirect)
    this.$router.push({ name: "LoginToken" });
    }
  },
    mounted()
    {
      this.SetToken()
    }
}
</script>
